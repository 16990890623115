html {
    height: 100%;
    margin: 0;
    overflow: auto;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Josefin Slab";
    font-weight: lighter;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    color: #4a4a4a;
}

a {
    text-decoration: none;
    color: black;
    line-height: 0;
}

p {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

p,
a,
ul,
li,
h3 {
    padding: 0;
    margin: 0;
}

button {
    font-family: "Josefin Slab";
    font-weight: bold;
}

#root {
    height: 100%;
}

.ms-grid-container {
    display: grid;
    height: 100%;
    grid-template-rows: 0.1fr auto 0.5fr;
}

/* nav bar styles start */

.ms-nav-bar {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
}

.ms-logo-href {
    display: flex;
    align-items: center;
    margin: 2%;
}

.ms-nav-text-item>img {
    width: 27px;
    height: 30px;
    vertical-align: bottom;
    margin-right: 3px;
}

.ms-nav-text-item {
    line-height: 1em;
    font-size: 30px;
    font-weight: bolder;
    display: inline-block
}

/* nav bar styles end */

.ms-section {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px auto 50px;
    background-image: url("./hill.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top center;
    overflow: unset;
    padding: 0, 2em, 0, 2em;
    line-height: 1.5;
}

.ms-wizard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media all and (min-width: 768px) {

    /* For desktop: */
    .ms-wizard {
        min-height: 500px;
    }
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .ms-grid-container {
        grid-template-rows: 0.1fr auto 0.5fr;
    }

    .ms-section {
        grid-auto-rows: 40px auto 65px;
        overflow: unset;
    }

    .ms-logo-href {
        justify-content: center;
    }
}

/* footer styles start */

.ms-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: grey;
    background-color: black;
}

.ms-footer-top {
    display: flex;
    justify-content: space-between;
    width: 8%;
    min-width: 100px;
    margin-bottom: .75em;
}

.ms-footer-top img {
    width: 32px;
    border-radius: 100%;
    border: 5px solid white;
    background-color: white;
}

.ms-footer-body {
    font-family: "Josefin Slab";
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 80%;
    margin-bottom: 1%;
    margin-top: 1%;
}

/* footer styles end */