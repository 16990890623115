/* Modal */

.ms-modal {
    -moz-animation-name: fade-in .25s ease-in;
    -ms-animation-name: fade-in .25s ease-in;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -o-animation-name: fade-in .25s ease-in;
    -webkit-animation: fade-in .25s ease-in;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    animation-name: fade-in .25s ease-in;
    background-color: rgba(0, 0, 0, .4);
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    overflow-y: scroll;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.ms-modal-wrapper {
    display: flex;
    padding: 0 30px
}

.ms-modal-content {
    display: flex;
    min-height: 60%;
    background-color: white;
    min-width: 70%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    max-width: 890px;
    box-sizing: border-box;
    border-radius: 9px;
    padding: 35px;
}

.ms-modal-content h3 {
    font-size: 30px;
    color: rgb(70, 178, 170);
}

.ms-modal-content p {
    text-align: center;
    margin: 0;
    padding: 0;
}

@media screen and (max-device-width: 600px) {
    .ms-modal-content {
        margin-top: 300px;
        padding: 10px;
    }

    .fb-modal {
        width: 90%
    }

    .modal-header h3 {
        padding-left: 10px;
    }
}

/* modal styles end */

.modal-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
}

.modal-header-close {
    border-radius: 290486px;
    padding-left: 1em;
    padding-right: 1em !important;
    align-items: center;
    background-color: rgb(255, 221, 87);
    box-shadow: 0 0 0 0.125em rgb(255 221 87 / 25%);
    border-color: transparent;
    color: rgba(0, 0, 0, .7);
    -moz-appearance: none;
    -webkit-appearance: none;
    align-items: center;
    border: 1px solid transparent;
    display: inline-flex;
    height: 2.25em;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
}

textarea::placeholder {
    font-family: "Josefin Slab";
    font-weight: lighter;
    font-style: normal;
}

textarea {
    box-sizing: border-box;
    max-width: 80%;
    font-family: inherit;
    font-size: inherit;
}