/* section styles end */

.ms-wiz-step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.ms-wiz-step p {
  font-size: 20px;
}

.ms-page-large-text {
  font-size: 25.6px;
  font-weight: 400;
}

.ms-teal-text {
  color: #007f7f;
  font-weight: bolder;

}

.ms-page-smaller-text {
  font-size: 20px;
}

.ms-step-question {
  font-size: 18px;
  font-weight: 550;
}

.ms-button {
  border-radius: 290486px;
  padding-left: 1em;
  padding-right: 1em !important;
  align-items: center;
  /* background-color: rgb(255, 221, 87); */
  background-color: #007f7f;
  font-size: 1.25rem;
  box-shadow: 0 0 0 0.125em #007f7f;
  border-color: transparent;
  color: rgba(255, 255, 255);
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  display: inline-flex;
  height: 2.25em;
  line-height: 1.5;
  padding-bottom: calc(.375em - 1px);
  padding-top: calc(.375em - 1px);
  position: relative;
  vertical-align: top;
  cursor: pointer;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  margin-top: 1%;
}

.ms-button-div {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin-top: 1%;
}

.ms-text-tt {
  padding-top: 1%;
  padding-bottom: 1%;
  margin-bottom: .75em;
}

.ms-text-tt p {
  font-size: 120%;
  text-align: justify;
  line-height: 1.5em;
}

.helper-text {
  width: 19px;
  height: 19px;
  margin-left: 1px;
}

.OrangeTooltip {
  background-color: darkgoldenrod !important;
  color: #fff !important;
  fill: #fff !important;
}

.Tooltips {
  width: 100%;
  flex-grow: 1;
  display: flexbox;
  justify-content: space-between;
  align-items: center;
}

.Tooltip_Wrapper__3AlJ0 {
  top: 2px;
  left: 4px;
}

.ms-tooltip {
  background-color: rgb(245, 245, 245);
  color: black;
  fill: black;
  font-size: 120% !important;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ms-error-text {
  color: red;
}

.ms-field-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: .75rem;
  font-size: 120%;
}

.ms-field {
  font-size: 120%;
}

select {
  min-width: 60px;
  border: 1px solid #CCC;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white 96% / 15% no-repeat;
  padding: 2%;
  font-family: "Josefin Slab";
  font-size: 16px;
}

/* Radio Styles */

.ms-radio-div ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

ul li {
  color: black;
  display: flex;
  position: relative;
  align-items: center;
  min-height: 40px;
}

ul li input[type=radio] {
  position: absolute;
  visibility: hidden;
}

ul li label {
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 18px;
  padding: 0px 0px 0px 40px;
  margin: 0px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

ul li:hover {
  color: #ffcc00;
}

ul li .check {
  display: block;
  position: absolute;
  border: 3px solid black;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

ul li:hover .check {
  border: 3px solid #ffcc00;
}

ul li .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 7px;
  width: 7px;
  top: 4px;
  left: 4px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked~.check {
  border: 3px solid #ffcc00;
}

input[type=radio]:checked~.check::before {
  background: #ffcc00;
}

input[type=radio]:checked~label {
  color: black;
}

@media all and (min-width: 768px) {

  /* For desktop: */
  .ms-field-select {
    min-width: 280px;
  }

  .ms-field {
    margin-bottom: 1%;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (max-width: 768px) {

  /* For mobile phones: */
  .ms-wiz-step p {
    font-size: 140%;
  }

  .ms-wiz-step {
    padding: 20px;
  }
}

.ms-form-div {
  min-width: 50%;
}