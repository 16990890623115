.ms-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ms-results-large-text {
  font-size: 2rem;
}

.title {
  text-align: center;
  font-family: "Josefin Slab";
  font-weight: lighter;
}

#products {
  text-align: center;
  font-family: "Josefin Slab";
  font-weight: lighter;
  border-collapse: collapse;
  border: 3px solid #007f7f;
  table-layout: fixed;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

#products td,
#products th {
  border: 1px solid #007f7f;
  padding: 8px;
  text-align: center;
}

.img {
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: none;
  text-align: center;
}

#products th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #007f7f;
  color: rgba(255, 255, 255);
}

.header {
  font-family: "Josefin Slab";
  font-weight: lighter;
}

.subheader {
  font-family: "Josefin Slab";
  font-weight: lighter;
}

#mobileprod {
  text-align: center;
  font-family: "Josefin Slab";
  font-weight: lighter;
  border-collapse: collapse;
  border: 3px solid #007f7f;
  table-layout: fixed;
  /* margin: 0px 0px 0px 25px; */
}

.msimage {
  height: 200px;
}

.is-centered p {
  margin-right: 0px !important;
}

.results-button {
  margin: auto;
  width: 20% !important;
  padding-top: 1%;
}

#products tbody {
  background-color: white;
}